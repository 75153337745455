'use strict';

function compareAscending(value, other) {
  if (value !== other) {
    var valIsDefined = value !== undefined;
    var valIsNull = value === null;
    var othIsDefined = other !== undefined;
    var othIsNull = other === null;
    if (!othIsNull && value > other || valIsNull && othIsDefined || !valIsDefined) {
      return 1;
    }
    if (!valIsNull && value < other || othIsNull && valIsDefined || !othIsDefined) {
      return -1;
    }
  }
  return 0;
}

/**
 * @param {Array<object>} collection object with keys in attributes
 * @param {Array<string>} iteratees attributes
 * @param {Array<string>} orders asc | desc
 * @return {Array<object>} sorted collection
 */
function orderBy(collection, iteratees, orders) {
  if (!Array.isArray(collection)) {
    return [];
  }
  if (!Array.isArray(orders)) {
    orders = [];
  }
  var result = collection.map(function (value, index) {
    return {
      criteria: iteratees.map(function (iteratee) {
        return value[iteratee];
      }),
      index: index,
      value: value
    };
  });
  result.sort(function comparer(object, other) {
    var index = -1;
    while (++index < object.criteria.length) {
      var res = compareAscending(object.criteria[index], other.criteria[index]);
      if (res) {
        if (index >= orders.length) {
          return res;
        }
        if (orders[index] === 'desc') {
          return -res;
        }
        return res;
      }
    }

    // This ensures a stable sort in V8 and other engines.
    // See https://bugs.chromium.org/p/v8/issues/detail?id=90 for more details.
    return object.index - other.index;
  });
  return result.map(function (res) {
    return res.value;
  });
}
module.exports = orderBy;