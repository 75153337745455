import {
  RefinementListConnectorParams,
  RefinementListItem,
  RefinementListWidgetDescription
} from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList';
import {TypedBaseWidgetComponent} from '@core/instantsearch/types-based-widget';
import {noop} from 'instantsearch.js/es/lib/utils';
import {Component, computed, inject, input, signal, WritableSignal} from '@angular/core';
import {connectRefinementList} from 'instantsearch.js/es/connectors';
import {InstantSearchComponent} from '@core/instantsearch/components/instantsearch.component';
import {parseNumberInput} from '@core/instantsearch/instantsearch-utils';

export const defaultSortBy = ['count:desc','name:asc'] as Array<'count:desc'|'name:asc'>;

@Component({
  selector: 'app-instantsearch-base-refinement-list',
  template: '<p>Should not be used directly</p>',
  standalone: true,
})
export class InstantSearchBaseRefinementListComponent extends TypedBaseWidgetComponent<
  RefinementListWidgetDescription,
  RefinementListConnectorParams
> {

  instantSearchInstance = inject(InstantSearchComponent)

  isHidden = computed(() => this.state().items.length === 0)


  public readonly showMoreLabel = input<string>('Show more');
  public readonly showLessLabel = input<string>('Show less');
  public readonly searchable = input<boolean>();
  public readonly searchPlaceholder = input<string>('Search here...');

  // instance options
  public readonly attribute = input.required<RefinementListConnectorParams['attribute']>();
  public readonly operator = input<RefinementListConnectorParams['operator']>();
  public readonly limit = input<RefinementListConnectorParams['limit']>();
  public readonly showMore = input<RefinementListConnectorParams['showMore']>();
  public readonly showMoreLimit = input<RefinementListConnectorParams['showMoreLimit']>();
  public readonly sortBy = input<RefinementListConnectorParams['sortBy']>();
  public readonly transformItems = input<RefinementListConnectorParams['transformItems']>();

  customTransformItems?: RefinementListConnectorParams['transformItems'];

  public override state: WritableSignal<RefinementListWidgetDescription['renderState']> = signal({
    canRefine: false,
    canToggleShowMore: false,
    createURL: () => '',
    isShowingMore: false,
    items: [],
    refine: noop,
    toggleShowMore: noop,
    searchForItems: noop,
    isFromSearch: false,
    hasExhaustiveItems: false,
    sendEvent: noop,
  });

  constructor() {
    super('RefinementList');
  }

  public override ngOnInit() {
    this.createWidget(
      connectRefinementList,
      {
        showMore: this.showMore(),
        limit: parseNumberInput(this.limit()),
        showMoreLimit: parseNumberInput(this.showMoreLimit()),
        attribute: this.attribute(),
        operator: this.operator(),
        sortBy: this.sortBy(),
        escapeFacetValues: true,
        transformItems: this.customTransformItems ?? this.transformItems(),
      },
      {
        $$widgetType: 'ais.refinementList',
      }
    );

    super.ngOnInit();
  }

  public refine(event: MouseEvent, item: RefinementListItem) {
    event.preventDefault();
    event.stopPropagation();

    if (this.state().canRefine) {
      // update UI directly, it will update the checkbox state
      item.isRefined = !item.isRefined;

      // refine through Algolia API
      this.state().refine(item.value);
    }
  }

}
